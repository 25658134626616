import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Mitarbeiter from "./cardMitarbeiter"
import "../styles/about.css"

import imgMichael from "../assets/about/michael.jpg"

export default () => (
  <Container>
    <Row className="d-flex align-items-center">
      <Col sm={12} md={8} className="about-textbox">
        <h2>
          <p className="text-center">
            Steht uns mit seiner Erfahrung zur Seite:
          </p>
          <hr />
          <p className="text-center">Michael Bürgel</p>
        </h2>
      </Col>
      <Col sm={12} md={4}>
        <div className="d-flex align-items-center">
          <Mitarbeiter Name="" Description="" Image={imgMichael} />
        </div>
      </Col>
    </Row>
  </Container>
)
