import "../styles/about.css"

import { Col, Container, Row } from "react-bootstrap"

import Mitarbeiter from "./cardMitarbeiter"
import React from "react"
import imgAlfons from "../assets/about/alfons.jpg"
import imgJulia from "../assets/about/bild-folgt.jpg"
import imgAnton from "../assets/about/bild-folgt.jpg"
import imgEryk from "../assets/about/bild-folgt.jpg"
import imgIbrahim from "../assets/about/ibrahim.jpg"
import imgJasmin from "../assets/about/jasmin.jpg"
import imgMario from "../assets/about/mario.jpg"
import imgMiro from "../assets/about/miro.jpg"
import imgNico from "../assets/about/nico2.jpg"
import imgPierre from "../assets/about/pierre.jpg"

export default () => (
  <Container>
    <Row className="show-grid">
      <Col xs={12} sm={12} md={true}>
        <Mitarbeiter Name="Miro Graf" Description="" Image={imgMiro} />
      </Col>
      <Col xs={12} sm={12} md={true}>
        <Mitarbeiter Name="Jasmin Stejskal" Description="" Image={imgJasmin} />
      </Col>
      <Col xs={12} sm={12} md={true}>
        <Mitarbeiter Name="Julia Kilinska" Description="" Image={imgJulia} />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col xs={12} sm={12} md={true}>
        <Mitarbeiter Name="Mario Stejskal" Description="" Image={imgMario} />
      </Col>
      <Col xs={12} sm={12} md={true}>
        <Mitarbeiter Name="Alfons Pelzer" Description="" Image={imgAlfons} />
      </Col>
      <Col xs={12} sm={12} md={true}>
        <Mitarbeiter Name="Nico Schippke" Description="" Image={imgNico} />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col xs={12} sm={12} md={true} >
        <Row>
          <Col xs={12} sm={12} md={true}>
            <Mitarbeiter Name="Anton Majer" Description="" Image={imgAnton} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={true}>
            <Mitarbeiter Name="Eryk Wlodarczyk" Description="" Image={imgEryk} />
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={true} >
        <Mitarbeiter Name="Jean-Pierre Siegmann" Description="" Image={imgPierre} />
      </Col>
      <Col xs={12} sm={12} md={true}>
        <Mitarbeiter Name="Ibrahim Taha" Description="" Image={imgIbrahim} />
      </Col>
    </Row>
  </Container>
)
