import React from "react"
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react"
import "../styles/history.css"

export default () => (
  <div className="history-timeline">
    <h2>Unsere Historie</h2>
    <hr />
    <Timeline lineColor={"dimgray"}>
      <TimelineItem
        className="history-item-red"
        key="012"
        dateInnerStyle={{ background: "#E2001A", color: "#FFF" }}
        style={{ color: "#E2001A" }}
        dateText="2019"
      >
        <h5>Firmenübergabe an Miro Graf</h5>
        <h6>Dormagen-Hackenbroich</h6>
      </TimelineItem>
      <p style={{ height: "14vh" }} />
      <TimelineItem
        className="history-item-black"
        key="011"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="2015"
      >
        <h5>Miro Graf verstärkt das Team der Dachdeckerei</h5>
        <h6>Dormagen-Hackenbroich</h6>
      </TimelineItem>
      <p style={{ height: "11vh" }} />
      <TimelineItem
        className="history-item-black"
        key="010"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="2014"
      >
        <h5>Michael Bürgel sucht einen Nachfolger</h5>
        <h6>Dormagen-Hackenbroich</h6>
      </TimelineItem>
      <p style={{ height: "14vh" }} />
      <TimelineItem
        className="history-item-black"
        key="009"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="2010"
      >
        <h5>Miro Graf besteht seine Meisterprüfung</h5>
        <h6>Düsseldorf</h6>
      </TimelineItem>
      <p style={{ height: "11vh" }} />
      <TimelineItem
        className="history-item-black"
        key="008"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="2009"
      >
        <h5>Michael Bürgel erhält den silbernen Meisterbrief</h5>
        <h6>Düsseldorf</h6>
      </TimelineItem>
      <p style={{ height: "17vh" }} />
      <TimelineItem
        className="history-item-black"
        key="007"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="2002"
      >
        <h5>Unsere erste Webseite geht online</h5>
        <h6>www.dachdeckerei-buergel.de</h6>
      </TimelineItem>
      <p style={{ height: "14vh" }} />
      <TimelineItem
        className="history-item-black"
        key="006"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="1998"
      >
        <h5>Der erste eigene Kranwagen</h5>
        <h6>Dormagen-Hackenbroich</h6>
      </TimelineItem>
      <p style={{ height: "15vh" }} />
      <TimelineItem
        className="history-item-red"
        key="005"
        dateInnerStyle={{ background: "#E2001A", color: "#FFF" }}
        style={{ color: "#E2001A" }}
        dateText="1993"
      >
        <h5>Firmenübergabe an Michael Bürgel</h5>
        <h6>Dormagen-Hackenbroich</h6>
      </TimelineItem>
      <p style={{ height: "13vh" }} />
      <TimelineItem
        className="history-item-black"
        key="004"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="1990"
      >
        <h5>Fertigstellung der eigenen Werkstatt</h5>
        <h6>Dormagen-Hackenbroich</h6>
      </TimelineItem>
      <p style={{ height: "16vh" }} />
      <TimelineItem
        className="history-item-black"
        key="003"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="1984"
      >
        <h5>Michael Bürgel besteht seine Meisterprüfung</h5>
        <h6>Düsseldorf</h6>
      </TimelineItem>
      <p style={{ height: "35vh" }} />
      <TimelineItem
        className="history-item-red"
        key="002"
        dateInnerStyle={{ background: "#E2001A", color: "#FFF" }}
        style={{ color: "#E2001A" }}
        dateText="1959"
      >
        <h5>Firmengründung durch Paul Bürgel</h5>
        <h6>Dormagen-Hackenbroich</h6>
      </TimelineItem>
      <p style={{ height: "13vh" }} />
      <TimelineItem
        className="history-item-black"
        key="001"
        dateInnerStyle={{ background: "#000", color: "#E5E5E5" }}
        dateText="1956"
      >
        <h5>Paul Bürgel besteht seine Meisterprüfung</h5>
        <h6>Köln</h6>
      </TimelineItem>
    </Timeline>
  </div>
)
