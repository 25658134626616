import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import History from "../components/history"
import Header from "../components/header"
import Footer from "../components/footer"
import Carousel from "../components/carouselAbout"
import CarouselTeam from "../components/carouselTeam"
import SloganBox from "../components/boxSlogan"
import ListeMitarbeiter from "../components/listMitarbeiter"
import ListeZertifikate from "../components/listZertifikate"
import ContactBox from "../components/boxContact"
import MichaelBox from "../components/boxMichael"
import "../styles/about.css"

import imgConstruction_450 from "../assets/about/underconstruction_450.jpg"
import imgConstruction_768 from "../assets/about/underconstruction_768.jpg"
import imgConstruction_1920 from "../assets/about/underconstruction_1920.jpg"
import imgConstruction_3840 from "../assets/about/underconstruction_3840.jpg"

export default () => (
  <div>
    <Header />
    <Carousel />
    <div className="contentbox white">
      <Container className="text-center">
        <h1>Was uns ausmacht</h1>
        <hr />
        <Row>
          <Col>
            <h5>Erfahrung</h5>
            <p />
            <div className="text-left">
              Unser Team hat zusammen über 100 Jahre Erfahrung. Ihre Wünsche und
              Vorstellungen werden fachgerecht und handwerklich einwandfreie
              umgesetzt.
            </div>
            <p />
          </Col>
          <Col>
            <h5>Beständigkeit</h5>
            <p />
            <div className="text-left">
              Fast alle Mitarbeiter wurden von uns ausgebildet und sind schon 25
              Jahre in unserer Firma
            </div>
            <p />
          </Col>
          <Col>
            <h5>Passende Werkzeuge</h5>
            <p />
            <div className="text-left">
              Dachsanierungsarbeiten sind Dank dem eigenen Kranwagen kein
              Problem für uns. Damit ist keine Lagerung von Material auf Ihrem
              Grundstück notwendig.
            </div>
            <p />
          </Col>
          <Col>
            <h5>
              24 Stunden
              <br />
              Notdienst
            </h5>
            <div className="text-left">
              Auch in kritischen Fällen sind wir für sie da. Unser Notdienst
              hilft ihnen weiter.
            </div>
            <p />
          </Col>
        </Row>
      </Container>
    </div>
    <div className="arrow-up gray" />
    <div className="text-center contentbox gray about about-nopadding">
      <Container fluid>
        <Row className="d-flex align-items-center">
          <Col sm={12} md={true} className="about-textbox">
            <h1>
              Unser Team
              <p />
              <hr />
              <p />
              Eine starke Mannschaft
            </h1>
          </Col>
          <Col sm={12} md={true}>
            <Row>
              <div className="d-flex align-items-center">
                <CarouselTeam />
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="arrow-up white push" />
    <div className="contentbox white">
      <ListeMitarbeiter />
    </div>
    <SloganBox Color="gray" />
    <div className="arrow-up white push" />
    <div className="contentbox white">
      <MichaelBox />
    </div>
    <div className="arrow-up gray" />
    <div className="text-center contentbox gray about">
      <Image
        fluid
        src={imgConstruction_768}
        srcSet={`${imgConstruction_450} 450w, ${imgConstruction_768} 768w, ${imgConstruction_1920} 1925w, ${imgConstruction_3840} 3840w`}
      />
    </div>
    <div className="arrow-up white" />
    <div className="contentbox white">
      <Container className="text-center" fluid>
        <History />
      </Container>
    </div>
    <div className="arrow-up gray" />
    <div className="contentbox gray foot">
      <ListeZertifikate />
    </div>
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
