import React from "react"
import { Card } from "react-bootstrap"
import Pulse from "react-reveal/Pulse"

export default props => (
  <div class="mitarbeiter-card">
    <Pulse>
      <Card border="danger">
        <Card.Img variant="top" src={props.Image} />
        <Card.Body>
          <Card.Title>{props.Name}</Card.Title>
          <Card.Text>{props.Description}</Card.Text>
        </Card.Body>
      </Card>
    </Pulse>
  </div>
)
