import React from "react"
import { Carousel, Image } from "react-bootstrap"

import img01_512 from "../assets/about/team02_512.jpg"
import img01_1024 from "../assets/about/team02_1024.jpg"

export default () => (
  <div>
    <Carousel
      className="text-center"
      indicators={false}
      pauseOnHover={false}
      interval={"3000"}
      fade
      fluid
    >
      <Carousel.Item>
        <Image
          fluid
          src={img01_1024}
          srcSet={`${img01_512} 512w, ${img01_1024} 1024w`}
          alt=""
        />
      </Carousel.Item>
    </Carousel>
  </div>
)
